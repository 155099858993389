import axios from 'axios';

const baseURL = window._env.REACT_APP_DEEPL_API_BASE_URL;
const auth_key = window._env.REACT_APP_DEEPL_AUTH_KEY || '';

export const getTranslation = (text: any, target_lang: any) => {
    const params = new URLSearchParams();
    params.append('auth_key', auth_key);
    params.append('text', text);
    params.append('target_lang', target_lang);
    console.log(baseURL);
    console.log(params);
    return axios.post(baseURL, params)
};

export default {
    getTranslation
}