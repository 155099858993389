import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import _ from "lodash";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import "./SearchPage.scss";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { getRecordingsByAdmin, getRecordings, getSearchParams } from '../../../actions/recordingAction'
import { getUsers } from '../../../actions/userAction';
import { Autocomplete } from "@material-ui/lab";

const mapDispatchToProps = (dispatch) => ({
  getRecordingsByAdmin: (searchParams) => dispatch(getRecordingsByAdmin(searchParams)),
  getRecordings: (userID) => dispatch(getRecordings(userID)),
  getUsers: () => dispatch(getUsers()),
  getSearchParams: () => dispatch(getSearchParams()),

});

const mapStateToProps = (state: any) => ({
  recordings: state.recordings.recordings,
  urlParamValues: state.recordings.paramValues,
  users: state.user.users
});

function Search({
  recordings = [],
  users = [],
  getUsers,
  urlParamValues = [],
  getRecordingsByAdmin,
  getRecordings,
  getSearchParams
}: any) {
  let history = useHistory();
  const { t } = useTranslation();

  const queryString = window.location.search; 
  const urlParams = new URLSearchParams(queryString);

  const [searchParams, setSearchParams] = React.useState<any>({
    uuid: "",
    coursename : urlParams.get("coursename") || "",
    quizname: urlParams.get("quizname") || "" ,
    question: urlParams.get("question") || "",
  });

  const [cursor] = React.useState("pointer");
  let courseNameValues :any = [];
  let quizNameValues :any = [];
  let questionValues :any = []
  
  useEffect(() => {
    getUsers();
    getSearchParams();  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!_.isEmpty(urlParamValues)) {
      
      initializeDropdownValues()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParamValues]);

  const initializeDropdownValues = () => {
    let { coursename, quizname, question } = searchParams

    if(!courseNameValues.includes(coursename)){
      coursename = ""
    }
    if(!quizNameValues.includes(quizname)){
      quizname = ""
    }
    
    if(!questionValues.includes(question)){
      question = ""
    } 
    setSearchParams({ ...searchParams, coursename, quizname, question});
  };

  const handleUuidChange = (event: any, user) => {
    const uuid = _.get(user, 'uuid', "")
    setSearchParams({ ...searchParams, uuid});
  };

  const handleCourseNameChange = (event: any, value) => {
    setSearchParams({ ...searchParams, coursename: event.target.value || "" });
  };

  const handleQuizChange = (event: any) => {
    setSearchParams({ ...searchParams, quizname: event.target.value || "" });
  };

  const handleQuestionChange = (event: any) => {
    setSearchParams({ ...searchParams, question: event.target.value || "" });
  };

  const getValues = () => {
   urlParamValues.forEach(element => {
    courseNameValues = element.coursename
    quizNameValues = element.quizname
    questionValues = element.question
   });

  };

  const redirectToRecording = (recording:any) => {
    history.push(`/recordings/${recording._id}/?uuid=${recording.uuid}&courseid=${recording.courseid}&coursename=${recording.coursename}&quiz=${recording.quiz}&quizname=${recording.quizname}&questionid=${recording.questionid}&question=${recording.question}`)
  };

  const submit = (event: any) => {
    event.preventDefault();
    getRecordingsByAdmin(searchParams);
  };
  return (
    <div>
      {getValues()}
      <div className="wrapper">
        <form onSubmit={submit}>
          <div className="input">
            <div className="textfield">
              <Autocomplete
                id="userinput"
                options={users}
                getOptionLabel={(option: any) => option.name}
                style={{ marginBottom: "20px", width: "100%" }}
                onChange={handleUuidChange}
                clearOnEscape
                blurOnSelect
                renderInput={(params) => (
                  <TextField {...params} label="User" type="users" value={searchParams.uuid} />
                )}
              />
            </div>
            <div className="textfield">
              <Autocomplete
                id="userinput"
                options={courseNameValues}
                style={{ marginBottom: "20px", width: "100%" }}
                onChange={handleCourseNameChange}
                clearOnEscape
                blurOnSelect
                renderInput={(params) => (
                  <TextField {...params} label={t('COURSENAME')} type="users" value={searchParams.coursename} />
                )}
              />
            </div>
            <div className="textfield">
              <Autocomplete
                id="userinput"
                options={quizNameValues}
                style={{ marginBottom: "20px", width: "100%" }}
                onChange={handleQuizChange}
                clearOnEscape
                blurOnSelect
                renderInput={(params) => (
                  <TextField {...params} label={t('QUIZNAME')} type="users" value={searchParams.quizname} />
                )}
              />
            </div>
            <div className="textfield">
              <Autocomplete
                id="userinput"
                options={questionValues}
                style={{ marginBottom: "20px", width: "100%" }}
                onChange={handleQuestionChange}
                clearOnEscape
                blurOnSelect
                renderInput={(params) => (
                  <TextField {...params} label={t('QUESTION')} type="users" value={searchParams.question} />
                )}
              />
            </div>
          </div>
          <div className="btn">
            <Button type="submit" variant="outlined" color="primary">
            {t('SEARCH')}
            </Button>
          </div>
        </form>
        <Table aria-label="simple table" style={{width: "90%"}}>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="center">
                {t('COURSENAME')}
              </TableCell>
              <TableCell align="center">
                {t('QUIZNAME')}
              </TableCell>
              <TableCell align="center">
                {t('QUESTION')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {recordings.map((recording: any) => (
              <TableRow
                hover
                style={{ cursor: cursor }}
                onClick={() =>redirectToRecording(recording)}
                key={recording._id}
              >
                <TableCell>
                  {recording._id}
                </TableCell>
                <TableCell align="center">{recording.coursename}</TableCell>
                <TableCell align="center">{recording.quizname}</TableCell>
                <TableCell align="center">{recording.question}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Search);
