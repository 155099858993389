export const isMobile = () => {
    let isMobile = RegExp(/Android|webOS|iPhone|iPod|iPad/i)
        .test(navigator.userAgent);

    if (!isMobile) {
        const isMac = RegExp(/Macintosh/i).test(navigator.userAgent);

        if (isMac && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
        isMobile = true;
        }
    }
    return isMobile;
}