import React, { Component } from 'react';
import './UnauthorizedPage.scss';
import PanToolRoundedIcon from '@material-ui/icons/PanToolRounded';
import { withTranslation, WithTranslation } from 'react-i18next';
import { i18n } from 'i18next';


interface IState {
}

interface IProp {
  i18n: i18n,
  tReady: boolean,
  t: any
}

class UnauthorizedPage extends Component<IProp, IState, WithTranslation> {

 
  render() {
    const { t } = this.props;
    return (
    <div className="unauthPage">
      <div className="image">
        <PanToolRoundedIcon style={{ fontSize: 200, color: "red"}} />
        <h1>STOP</h1>
      </div>
      <h2>{t('UNAUTHORIZED').toUpperCase()}</h2>
      <h2>{t('ACCESS').toUpperCase()}</h2>
    </div>
    );
  }
}


export default withTranslation()(UnauthorizedPage);


