import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { withTranslation, WithTranslation } from 'react-i18next';
import { isMobile } from '../../detectMobile' 

import { updateUser } from '../../actions/userAction'
import './SettingsPage.scss';

import HotkeysList from './HotkeysList/HotkeysList';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import { i18n } from 'i18next';

const mapStateToProps = (state) => ({
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  updateUser:(id, data) => dispatch(updateUser(id, data)),
});

interface IState {
  showToast?:any,
  microphones?:any,
  wsServer?:any,
  email?:string,
  germanActivated?:any,
  frenchActivated?:any,
  englishActivated?:any,
  dictateActivated?:any,
  showAsrPreviewText?:boolean,
  sendAsrResultsPerEmail?:boolean,
  selectedMicrophone?:any,
  showWaveform?:any,
  severity?:any,
  toastText?:any,
  fontSize?:any
}

interface IProp {
  i18n: i18n,
  tReady: boolean,
  t: any,
  user?: any,
  updateUser?:any
}

class SettingsPage extends Component<IProp, IState, WithTranslation> {
  constructor(props) {
    super(props);

    let dictateActivated = window._env.REACT_APP_DICTATE_MODE === 'true' ? true : false;

    if (props.user.dictateActivated !== undefined) {
      dictateActivated = props.user.dictateActivated
    }

    this.state = {
      showToast: false,
      toastText: '',
      microphones: [],
      wsServer: props.user.websocketUrl || window._env.REACT_APP_WEBSOCKET_URL,
      email: props.user.email,
      germanActivated: props.user.germanActivated || false,
      frenchActivated: props.user.frenchActivated || false,
      englishActivated: props.user.englishActivated || false,
      showAsrPreviewText: props.user.showAsrPreviewText || false,
      sendAsrResultsPerEmail: props.user.sendAsrResultsPerEmail || false,
      dictateActivated,
      showWaveform: props.user.showWaveform || false,
      selectedMicrophone: isMobile() ? props.user.microphoneMobile : props.user.microphone,
      fontSize: props.user.fontSize || 12
    };
  }

  getAllMicrophones = () => {
    const { t } = this.props;
    navigator.mediaDevices.getUserMedia({ audio: true })
    .then(() => {
      return navigator.mediaDevices.enumerateDevices()
    })
    .then((devices) => {
      const microphones = devices.filter((device) => device.deviceId !== 'default' && device.kind === 'audioinput');
      this.setState({ microphones })
    })
    .catch((error)=> {
      this.setState({ showToast: true, severity: 'error', toastText: t('UNABLE_TO_LOAD_MICROPHONE_LIST') + " error: " + error })
    })
  }

  handleMicrophoneChange = (event) => {
    const selectedMicrophone = event.target.value
    this.setState({ selectedMicrophone })
  }

  handleWebSocketChange = (event) => {
    const wsServer = event.target.value
    this.setState({ wsServer })
  }

  handleEmailChange = (event) => {
    const email = event.target.value
    this.setState({ email })
  }

  handleModeChange = (lang) => {
    this.setState({[lang]: !this.state[lang]})
  }

  handleTextChange = (event, key) => {
    const value = event.target.value
    this.setState({[key]: value})
  }

  componentDidMount() {
    this.getAllMicrophones();
  }

  updateUser = () => {
    const { t } = this.props;
    const {
      wsServer,
      frenchActivated,
      germanActivated,
      englishActivated,
      dictateActivated,
      selectedMicrophone,
      showWaveform,
      email,
      showAsrPreviewText,
      sendAsrResultsPerEmail,
      fontSize
    } = this.state
    const userid = this.props.user._id;
    let data: any = {
      germanActivated,
      frenchActivated,
      englishActivated,
      dictateActivated,
      showWaveform,
      websocketUrl: wsServer,
      email,
      showAsrPreviewText,
      sendAsrResultsPerEmail,
      fontSize
    };

    if (isMobile()) {
      data.microphoneMobile = selectedMicrophone;
    } else {
      data.microphone = selectedMicrophone;
    }

    this.props.updateUser(userid,data).then(resp => {
      this.setState({ showToast: true, severity: 'success', toastText: t('SETTINGSUPDATED')})
    }).catch(() => {
      this.setState({ showToast: true, severity: 'error', toastText: t('SETTINGSNOTUPDATED') })
    });
  }

  handleClose = (reason) => {
    this.setState({ showToast: false })
    if (reason === 'clickaway') {
      return;
    }
  }

  renderToast = () => {
    const vertical = 'top';
    const horizontal = 'center';
    return (
      this.state.showToast &&
        <Snackbar
          open={this.state.showToast}
          autoHideDuration={2000}
          onClose={this.handleClose}
          anchorOrigin={{ vertical, horizontal }}
          key={`${vertical},${horizontal}`}
        >
          <MuiAlert elevation={6} variant="filled" onClose={this.handleClose} severity={this.state.severity}>
            {this.state.toastText}
          </MuiAlert>
        </Snackbar>
    )
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        {
          this.renderToast()
        }
        <Typography className="title" variant="h5" id="tableTitle" component="div">
          {t('SETTINGS')}
        </Typography>
        <div className="select-container">
          <div className="formControl">
            <FormControl variant="outlined">
              <InputLabel id="select-microphone-input-label">{t('Microphone')}</InputLabel>
              <Select
                labelId="select-microphone-label"
                id="select-microphone"
                label="Microphone"
                value={this.state.selectedMicrophone}
                onChange={this.handleMicrophoneChange}
              >
              {
                this.state.microphones.map((micro, key) => (
                  <MenuItem key={key} value={micro.label}>{micro.label}</MenuItem>
                ))
              }
              </Select>
              <FormHelperText>{t('MICTEXT')}</FormHelperText>
            </FormControl>
          </div>
          {
            window._env.REACT_APP_SHOW_WEBSOCKET_URL === 'true' &&
            <div className="formControl">
              <TextField
                  fullWidth
                  id="full-width-outlined-basic"
                  label="Backend Server"
                  variant="outlined"
                  value={this.state.wsServer}
                  onChange={this.handleWebSocketChange}
              />
            </div>
          }
          {
            window._env.REACT_APP_SEND_ASR_RESULTS_VIA_EMAIL === 'true' &&
            <div className="formControl">
                <TextField
                    fullWidth
                    id="full-width-outlined-basic"
                    label="Email"
                    variant="outlined"
                    value={this.state.email}
                    onChange={this.handleEmailChange}
                />
            </div>
          }
          {
            window._env.REACT_APP_SHOW_ASR_PREVIEW_MODE === 'true' &&
            <div className="formControl">
              <FormControlLabel
                control={<Switch color="primary" checked={this.state.showAsrPreviewText} />}
                label={t('SHOW_ASR_PREVIEW_TEXT_LABEL')}
                labelPlacement="start"
                onChange={() => this.handleModeChange('showAsrPreviewText')}
              />
              <FormHelperText>{t('SHOW_ASR_PREVIEW_HELP_TEXT')}</FormHelperText>
            </div>
          }
          {
            window._env.REACT_APP_SEND_ASR_RESULTS_VIA_EMAIL === 'true' &&
            <div className="formControl">
              <FormControlLabel
                control={<Switch color="primary" checked={this.state.sendAsrResultsPerEmail} />}
                label={t('SEND_ASR_RESULTS_PER_EMAIL_LABEL')}
                labelPlacement="start"
                onChange={() => this.handleModeChange('sendAsrResultsPerEmail')}
              />
              <FormHelperText>{t('SEND_ASR_RESULTS_PER_EMAIL_HELP_TEXT')}</FormHelperText>
            </div>
          }
          <div className="formControl">
            <FormControlLabel
              control={<Switch color="primary" checked={this.state.dictateActivated} />}
              label={t('DICTATE_MODE_LABEL')}
              labelPlacement="start"
              onChange={() => this.handleModeChange('dictateActivated')}
            />
            <FormHelperText>{t('DICTATETEXT')}</FormHelperText>
          </div>
          {
            window._env.REACT_APP_SHOW_WAVEFORM === 'true' &&
              <div className="formControl">
                <FormControlLabel
                    control={<Switch color="primary" checked={this.state.showWaveform} />}
                    label={t('SHOW_WAVEFORM')}
                    labelPlacement="start"
                    onChange={() => this.handleModeChange('showWaveform')}
                />
              </div>
          }
          <div className="translationSwitches">
          {
            window._env.REACT_APP_DEEPL_AUTH_KEY !== 'dev' &&
            <>
              <FormControlLabel
                  control={<Switch color="primary" checked={this.state.germanActivated} />}
                  label={t("GERMAN")}
                  labelPlacement="start"
                  onChange={() => this.handleModeChange('germanActivated')}
              />
              <FormControlLabel
                control={<Switch color="primary" checked={this.state.frenchActivated} />}
                label={t("FRENCH")}
                labelPlacement="start"
                onChange={() => this.handleModeChange('frenchActivated')}
              />
              <FormControlLabel
                control={<Switch checked={this.state.englishActivated} color="primary" />}
                label={t("ENGLISH")}
                labelPlacement="start"
                onChange={() => this.handleModeChange('englishActivated')}
              />
            </>
          }
          </div >
          {
            window._env.REACT_APP_CHANGE_FONT_SIZE === 'true' &&
              <div className="formControl">
                <TextField
                  id="full-width-outlined-basic"
                  label="Font Size in PX"
                  variant="outlined"
                  type="number"
                  value={this.state.fontSize}
                  onChange={(event) => this.handleTextChange(event, 'fontSize')}
                />
              </div>
          }
          <div className="btnSave">
            <Button variant="outlined" color="primary" onClick={() => this.updateUser()}>{t('SAVESETTINGS')}</Button>
          </div>
          <HotkeysList />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SettingsPage));
