export default (state = { users: [] as any}, action: any) => {
    switch (action.type) {
      case 'GET_USER_BY_UID_FULFILLED':
          return {
            ...state,
            ...action.payload.data
          }
      case 'GET_USERS_FULFILLED':
          return {
            ...state,
            users: action.payload.data
        }
      case 'CREATE_USER_FULFILLED':
          state.users.push(action.payload.data);
          return{
            ...state,
            users: state.users
          }
      case 'DELETE_USER_FULFILLED':
          return{
            ...state,
            users: action.payload.data
          }
      case 'UPDATE_USER_FULFILLED':
          return {
            ...state,
            ...action.payload.data,
            users: updateUserData(state.users, action.payload.data)
          }
    default:
        return state  
    }
}

const updateUserData = (users, newUser: any = {}) => {
      const newUsers= [...users];
      
      const index = newUsers.findIndex( ({_id}) => _id === newUser._id);

      if (index !== -1) {
        newUsers[index] = newUser;
      } else {
        newUsers.push(newUser);
      }

      return newUsers;
  }
  