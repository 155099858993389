import userApi  from '../api/user-api'

export const getUserByUuid = (uuid: any) => {
    return {
        type: 'GET_USER_BY_UID',
        payload: userApi.getUserByUuid(uuid),
    }
}

export const createUser = (user: any) => {
    return {
        type: 'CREATE_USER',
        payload: userApi.createUser(user),
    }
}

export const getUsers = () => {
    return {
        type: 'GET_USERS',
        payload: userApi.getUsers(),
    }
}

export const deleteUser = (id: any) => {
    return {
        type: 'DELETE_USER',
        payload: userApi.deleteUser(id),
    }
}


export const updateUser = (id: any, updatedUser: any) => {
    return {
        type: 'UPDATE_USER',
        payload: userApi.updateUser(id, updatedUser),
    }
}

export const login = (username: string, password: string) => {
    return {
        type: 'SIGNIN_ADMIN',
        payload: userApi.login(username, password),
    }
}

export const checkAuth = () => {
    return {
        type: 'CHECK_AUTH',
        payload: userApi.checkAuth()
    }
}