import React, { Component, Fragment } from 'react';
import _ from 'lodash'

import { hotkey_display } from 'react-keyboard-shortcuts'
import { WithTranslation, withTranslation } from 'react-i18next'

import { Typography } from '@material-ui/core'

import MicIcon from '@material-ui/icons/Mic';
import FolderIcon from '@material-ui/icons/Folder';

import './HotkeysList.scss'

interface IProp extends WithTranslation{}

class HotkeysList extends Component<IProp> {
  
  hotkey_sections = [
    {
      title: "HOTKEYS_TITLE_RECORD",
      titleIcon: MicIcon,
      isActive: window._env.REACT_APP_USE_RECORD_HOTKEYS,
      list: [
        {
          shortcut: window._env.REACT_APP_HOTKEY_RECORD_START,
          description: 'HOTKEY_START_RECORDING_DESC'
        },
        {
          shortcut: window._env.REACT_APP_HOTKEY_RECORD_STOP,
          description: 'HOTKEY_STOP_RECORDING_DESC'
        },
        {
          shortcut: window._env.REACT_APP_HOTKEY_RECORD_COPY_TO_CLIPBOARD,
          description: 'HOTKEY_COPY_ASR_TEXT_TO_CLIPBOARD_DESC'
        }
      ]
    },
    {
      title: "HOTKEYS_TITLE_AUDIO",
      titleIcon: FolderIcon,
      isActive: window._env.REACT_APP_USE_AUDIO_HOTKEYS,
      list: [
        {
          shortcut: window._env.REACT_APP_HOTKEY_AUDIO_FAST_FORWARD,
          description: 'HOTKEY_AUDIO_FAST_FORWARD_DESC'
        },
        {
          shortcut: window._env.REACT_APP_HOTKEY_AUDIO_REWIND,
          description: 'HOTKEY_AUDIO_REWIND_DESC'
        },
        {
          shortcut: window._env.REACT_APP_HOTKEY_AUDIO_STOP,
          description: 'HOTKEY_AUDIO_STOP_DESC'
        },
        {
          shortcut: window._env.REACT_APP_HOTKEY_AUDIO_DECREASE_SPEED,
          description: 'HOTKEY_AUDIO_DECREASE_SPEED_DESC'
        },
        {
          shortcut: window._env.REACT_APP_HOTKEY_AUDIO_INCREASE_SPEED,
          description: 'HOTKEY_AUDIO_INCREASE_SPEED_DESC'
        },
        {
          shortcut: window._env.REACT_APP_HOTKEY_AUDIO_COPY_TO_CLIPBOARD,
          description: 'HOTKEY_AUDIO_COPY_TO_CLIPBOARD_DESC'
        },
      ]
    }
  ]
  
  formatHotkeysList = (hotkeys_list) => (
    hotkeys_list.map((hotkey) => {
      let formattedShortcut: string = hotkey_display(hotkey.shortcut)
      
      // "+" can also be a key
      formattedShortcut = formattedShortcut.replace(/^\+/g, "_plus_")
      formattedShortcut = formattedShortcut.replace(/\+\+/g, "+_plus_")
      
      return {
        ...hotkey,
        shortcut: formattedShortcut.split(/\+/).map(_shortcut => _shortcut.replace(/_plus_/, '+'))
      }
    })
  )
  
  render() {
    const { t } = this.props
    let formatedHotkeySections = [] as any;
    
    this.hotkey_sections.forEach((hotkeySection) => {
      if (JSON.parse(hotkeySection.isActive)) {
        formatedHotkeySections = [
          ...formatedHotkeySections,
          {
            ...hotkeySection,
            list: this.formatHotkeysList(hotkeySection.list)
          }
        ]
      }
    })
    
    return (
      <>
        {!_.isEmpty(formatedHotkeySections) && (
          <div className='hotkeysListContainer'>
            <Typography variant='h5' align='center'>{t("HOTKEYS")}</Typography>
            <table className='hotKeysListTable'>
              {formatedHotkeySections.map((section: any) => !_.isEmpty(section) && (
                <Fragment key={section.title}>
                  <thead>
                    <tr>
                      <th colSpan={2}>
                        <section.titleIcon />
                        <Typography variant='h6' align='left'>{t(section.title)}</Typography>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                  {section.list.map((hotkey) => (
                      <tr key={hotkey.description}>
                        <td className="shortcut">
                          {hotkey.shortcut.map((key, index) => (
                            <span key={key}>
                              <span className='shortkey'>{key}</span>
                              <span>{index + 1 !== hotkey.shortcut.length ? '+' : ''}</span>
                            </span>
                          ))}
                        </td>
                        <td className='shortcutDesc'>
                          {t(hotkey.description)}
                        </td>
                      </tr>
                    )
                  )}
                  </tbody>
                </Fragment>
              ))}
            </table>
          </div>
        )}
      </>
    )
  }
}

export default withTranslation()(HotkeysList)

