import React, {useState} from "react";

import Typography from '@material-ui/core/Typography';
import { useTranslation } from "react-i18next";
import { TextField, Button, InputAdornment, Modal } from "@material-ui/core";
import { AccountCircle, Lock } from "@material-ui/icons";

import './SignIn.scss'

interface signInProps {
  signIn: any,
  errorMessage?: string
}

const SignIn = React.forwardRef<signInProps, any>(({signIn, errorMessage}, ref ) => {
  
  const { t } = useTranslation();
  
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  
  const redirectHomePage = () => {
    window.location.replace('/');
  }
  
  const handleUsernameChange = (event) => {
    const newUsername = event.target.value;
    setUsername(newUsername);
  }
  
  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
  }
  
  const handleSubmit = (event) => {
    event.preventDefault();
    signIn(username, password);
  }

  return (
    <Modal
      open={true}
    >
    <div className="SignInContainer">
        <form className="SignInBox" onSubmit={handleSubmit}>
          <Typography variant="h1">{t("SIGN_IN")}</Typography>
          <TextField 
            label={t("USERNAME")}
            name="username"
            onChange={handleUsernameChange}
            value={username}
            error={Boolean(errorMessage)}
            InputProps={{
              autoComplete: "username",
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
          >
          </TextField>
          <TextField 
            label={t("PASSWORD")}
            type="password"
            name="password"
            onChange={handlePasswordChange}
            value={password}
            error={Boolean(errorMessage)}
            InputProps={{
              autoComplete: "current-password",
              startAdornment: (
                <InputAdornment position="start">
                  <Lock />
                </InputAdornment>
              ),
            }}
          >
          </TextField>
          {errorMessage && (<p style={{color: "red"}}>{t(errorMessage)}</p>)}
          <div className="SignInButtons">
            <Button 
              variant="outlined" 
              color="primary" 
              onClick={redirectHomePage}
            >
              {t("CANCEL")}
            </Button>
            <Button 
              variant="contained" 
              color="primary" 
              type="submit"
            >
              {t("SIGN_IN")}
            </Button>
          </div>
        </form>
    </div>
    </Modal>
  );
});

export default SignIn;