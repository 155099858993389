
export default (state  = { translation: { frenchActivated: false, englishActivated: false, dictateActivated: false }}, action: any) => {
    switch (action.type) {
      case 'GET_TRANSLATION_FULFILLED':
        const { data } = action.payload;
        const lang = action.meta.target_lang;
        const text = data.translations[0].text
        return {
          ...state,
          [lang]: text,
        }
      case 'HANDLE_MODE_CHANGE':
        const { mode } = action.payload;
        return {
          ...state,
          [mode]: !state[mode],
        }
     default:
      return state
    }
   }