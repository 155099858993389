import {
  websocketConnectionOpen,
  websocketConnectionClosed,
  receivedWebsocketMessage,
  receivedWebsocketErrorMessage,
  receivedServerErrorMessage,
} from './actions/recordingAction';

import store from './index';
import _ from 'lodash';

const specificStatusCodeMappings = {
  '1000': 'Websocket Normal Closure',
  '1001': 'Websocket Going Away',
  '1002': 'Websocket Protocol Error',
  '1003': 'Websocket Unsupported Data',
  '1004': 'Websocket (For future)',
  '1005': 'Websocket No Status Received',
  '1006': 'Websocket Abnormal Closure',
  '1007': 'Websocket Invalid frame payload data',
  '1008': 'Websocket Policy Violation',
  '1009': 'Websocket Message too big',
  '1010': 'Websocket Missing Extension',
  '1011': 'Websocket Internal Error',
  '1012': 'Websocket Service Restart',
  '1013': 'Websocket Try Again Later',
  '1014': 'Websocket Bad Gateway',
  '1015': 'Websocket TLS Handshake'
};

const createWebSocket = (url: any) => {
  const socket = new WebSocket(url);

  // Connection opened
  socket.addEventListener('open', function (event: any) {
    store.dispatch(websocketConnectionOpen(event));
  });

  // Listen for messages
  socket.addEventListener('message', function (event: any) {
    const message = JSON.parse(event.data);
    console.log(message);
    const hypotheses = _.get(message, 'result.hypotheses', []);
    const final = _.get(message, 'result.final', []);
    const status = _.get(message, 'status');
    const serverMessage = _.get(message, 'message');
    const id = _.get(message, 'id');

    // Server status codes
	  // from https://github.com/alumae/kaldi-gstreamer-server
    // The following status codes are currently in use:

    // 0 -- Success. Usually used when recognition results are sent
    // 1 -- No speech. Sent when the incoming audio contains a large portion of silence or non-speech.
    // 9 -- Not available. Used when all recognizer processes are currently in use and recognition cannot be performed.

    if (status === 0) {
      store.dispatch(receivedWebsocketMessage({ hypotheses, final, id}));
    }

    if (status === 9 || status === 2 || status === 5) {
      store.dispatch(receivedServerErrorMessage(serverMessage));
    }
  });

  // Connection opened
  socket.addEventListener('error', function (event:any) {
    console.log('event error', event)
    store.dispatch(receivedWebsocketErrorMessage('WebSocket error: ' + event.code));
  });

  // Listen for messages
  socket.addEventListener('close', function (event: any) {
    console.log('event closo', event)
    const { code: eventCode = 0, wasClean } = event;
    const closeEvent = {
      ...event,
      wasClean,
      reason: specificStatusCodeMappings[eventCode] || 'unkown Websocket Error'
    };
    store.dispatch(websocketConnectionClosed(closeEvent));
  });

  return socket;
}

export default createWebSocket;
