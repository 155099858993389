export default (state = { recordings: [] as any, count: 0 as number, paramValues: [] as any }, action: any) => {
    switch (action.type) {
      case 'GET_RECORDINGS_FULFILLED':
          return {
              ...state,
              recordings: action.payload.data
          }
      case 'UPDATE_RECORDING_FULFILLED':
          return {
            ...state,
            recordings: updateRecordingData(state.recordings, action.payload.data),
          }
      case 'GET_RECORDING_BY_ID_FULFILLED':
          return {
            ...state,
            recordings: updateRecordingData(state.recordings, action.payload.data),
          }
      case 'GET_INCREMENTAL_RECORDING_NUMBER_FULFILLED':
        return {
          ...state,
          count: action.payload.data,
        }
      case 'GET_RECORDINGS_BY_ADMIN_FULFILLED':
        return {
          ...state,
          recordings: action.payload.data,
        }
      case 'GET_SEARCH_PARAMS_FULFILLED':
        return {
          ...state,
          paramValues: action.payload.data,
        }
    default:
        return state  
    }
}

const updateRecordingData = (recordings, newRecordingData: any= {}) => {
  const newRecordings = [...recordings];

  const index = newRecordings.findIndex( ({id}) => id === newRecordingData.id )

  if (index !== -1) {
    newRecordings[index] = newRecordingData;
  } else {
    recordings.push(newRecordingData);
  }

  return recordings;
}