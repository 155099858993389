import React, { Component, createRef } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { hotkeys } from 'react-keyboard-shortcuts'
import _ from 'lodash';
import SpeedIcon from '@material-ui/icons/Speed';

import { getRecordingById, updateRecording } from '../../actions/recordingAction'

import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import SaveIcon from '@material-ui/icons/Save';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { withTranslation, WithTranslation } from 'react-i18next';

import { EditorState, ContentState, convertToRaw, convertFromRaw } from 'draft-js';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import './RecordingText.scss';

import { i18n } from 'i18next';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';



const mapDispatchToProps = (dispatch) => ({
  getRecordingById: (id, userID) => dispatch(getRecordingById(id, userID)),
  updateRecording: (id, data) => dispatch(updateRecording(id, data))
});

const mapStateToProps = (state, ownProps) => {
  const recording = state.recordings.recordings.find((recordingEntry) => recordingEntry._id === ownProps.match.params.id) || {}
  return ({
    recording,
    user: state.user
  })
};

interface IState {
  isLoaded?: boolean,
  showToast?: boolean,
  title?: string,
  editorState?: any,
  toastText?: any,
  severity,
  speed: any,
}

interface IProp {
  recording: any,
  updateRecording?: any,
  match?: any,
  user?: any,
  i18n: i18n,
  tReady: boolean,
  t: any
  getRecordingById?: any,
  history?: any,
  handleClose?: any
}

const speedConfig = [
  {value : 0.2, name: "× 0.2"},
  {value : 0.25, name: "× 0.25"},
  {value : 0.5, name: "× 0.5"},
  {value : 0.75, name: "× 0.75"},
  {value : 1., name: "× 1"},
  {value : 1.25, name: "× 1.25"},
  {value : 1.5, name: "× 1.5"},
  {value : 1.75, name: "× 1.75"},
  {value : 2, name: "× 2"},
  {value : 2.25, name: "× 2.25"},
  {value : 2.5, name: "× 2.5"},
  {value : 2.75, name: "× 2.75"},
  {value : 3, name: "× 3"},
  {value : 3.25, name: "× 3.25"},
  {value : 3.5, name: "× 3.5"},
  {value : 3.75, name: "× 3.75"}
]

class RecordingDetail extends Component<IProp, IState, WithTranslation> {
  player: any;

  constructor(props) {
    super(props)
    this.state = {
      title: '',
      isLoaded: false,
      showToast: false,
      severity: '',
      editorState: this.initializeTextEditor(props),
      speed: 1,
    }
    this.player = createRef()
  }


  handleSpeedChange = (event) => {
    const value = _.get(event, 'target.value'); 
    this.setState({ speed: value });
    _.set(this.player,'current.audio.current.playbackRate', value);
  };

  hot_keys = {
    [window._env.REACT_APP_HOTKEY_AUDIO_FAST_FORWARD]: {
      priority: 1,
      handler: () => this.fastForward(),
    },
    [window._env.REACT_APP_HOTKEY_AUDIO_REWIND]: {
      priority: 1,
      handler: () => this.rewindAudio(),
    },
    [window._env.REACT_APP_HOTKEY_AUDIO_STOP]: {
      priority: 1,
      handler: () => this.stopAudio(),
    },
    [window._env.REACT_APP_HOTKEY_AUDIO_DECREASE_SPEED]: {
      priority: 1,
      handler: () => this.decreaseSpeed(),
    },
    [window._env.REACT_APP_HOTKEY_AUDIO_INCREASE_SPEED]: {
      priority: 1,
      handler: () => this.increaseSpeed(),
    },
    [window._env.REACT_APP_HOTKEY_AUDIO_COPY_TO_CLIPBOARD]: {
      priority: 1,
      handler: () => this.copyTextToClipboard(this.state.editorState.getCurrentContent().getPlainText()),
    },
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  updateRecording = () => {
    const { t } = this.props;
    const currentRecording = this.props.recording
    const rawContent = JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent()));
    const updatedEntry = {
      ...currentRecording,
      rawContent: rawContent,
    };

    this.props.updateRecording(this.props.match.params.id, updatedEntry).then(resp => {
      this.setState({ showToast: true, severity: 'success', toastText: t('TEXTSAVED') })
    }).catch(() => {
      this.setState({ showToast: true, severity: 'error', toastText: t('TEXTNOTSAVED') })
    });
  }

  handleClose = () => {
    this.setState({ showToast: false })
  };

  initializeTextEditor = (props) => {
    const rawContent = _.get(props, 'recording.rawContent');
    const text = _.get(props, 'recording.text', '');
    if (rawContent) {
      return EditorState.createWithContent(convertFromRaw(JSON.parse(rawContent)));
    }

    if (text) {
      return EditorState.createWithContent(ContentState.createFromText(text));
    }

    return EditorState.createEmpty();
  }

  fallbackCopyTextToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      const msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
  }

  copyTextToClipboard = (text) => {
    const { t } = this.props;

    if (!navigator.clipboard) {
      this.fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text)
      .then(() => {
        this.setState({ showToast: true, severity: 'success', toastText: t('COPY_TEXT_SUCCESSFULLY') })
      }, (err) => {
        this.setState({ showToast: true, severity: 'error', toastText: t('COPY_TEXT_UNSUCCESSFULLY') })
      });
  }

  fastForward = () => {
    if (_.get(this, 'player.current.audio')) {
      this.player.current.audio.current.currentTime += 5.0;
    }
  }

  rewindAudio = () => {
    if (_.get(this, 'player.current.audio')) {
      this.player.current.audio.current.currentTime -= 5.0;
    }
  }

  stopAudio = () => {
    const { paused } = this.player.current.audio.current;

    if (paused) {
      this.player.current.audio.current.play();
    } else {
      this.player.current.audio.current.pause();
    }
  }

  decreaseSpeed = () => {
    if (this.player.current.audio.current.playbackRate > 0.25) {
      this.player.current.audio.current.playbackRate -= 0.25;
      this.setState({ speed: this.player.current.audio.current.playbackRate })
    }
  }

  increaseSpeed = () => {
    if (this.player.current.audio.current.playbackRate < 3.75) {
      this.player.current.audio.current.playbackRate += 0.25;
      this.setState({ speed: this.player.current.audio.current.playbackRate })
    }
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.recording) !== JSON.stringify(this.props.recording)) {
      this.setState({
        editorState: this.initializeTextEditor(this.props)
      })
    }
  }

  componentDidMount() {
    const userID = this.props.user._id
    this.props.getRecordingById(this.props.match.params.id, userID)
  }

  renderToast = () => {
    const vertical = 'top';
    const horizontal = 'center';

    return (
      this.state.showToast &&
      <Snackbar
        open={this.state.showToast}
        autoHideDuration={2000}
        onClose={this.handleClose}
        anchorOrigin={{ vertical, horizontal }}
        key={`${vertical},${horizontal}`}
      >
        <MuiAlert elevation={6} variant="filled" onClose={this.handleClose} severity={this.state.severity}>
          {this.state.toastText}
        </MuiAlert>
      </Snackbar>
    )
  }

  render() {
    const { editorState } = this.state;
    const { t, recording } = this.props;
    return (
      <div>
        {
          this.renderToast()
        }
        <div className="content-container">
          <Typography variant="h5" className="title">
            {this.state.title}
          </Typography>
          <div className="box">
            {
              recording.audioURL ?
              <AudioPlayer style={{ borderBottom: "none" }}
                src={recording.audioURL}
                ref={this.player}
              />       :
              recording.errorMessage ?
              <span>{recording.errorMessage}</span>
              :
              <span>{t('AUDIO_CONVERTING_IN_PROGRESS')}</span>
            }

            <div className="speed" >
              <SpeedIcon style={{marginBottom:"5px", marginRight:"5px", color:"#868686"}}  />
              <InputLabel>Speed</InputLabel>
              <div className="selectSpeed">
                <Select
                  id="speedID"
                  value={this.state.speed}
                  onChange={this.handleSpeedChange}
                >
                  {speedConfig.map((cell) =>{
                    return <MenuItem value={cell.value}>{cell.name}</MenuItem>
                  })}
                  
                </Select>
              </div>
            </div>
          </div>
          <div className="editor">
            <Editor
              editorState={editorState}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              toolbarClassName="toolbar-class"
              wrapperStyle={{ border: "1px solid lightgrey", marginLeft: "50px", marginRight: "50px" }}
              editorStyle={{ height: "500px", padding: "20px", paddingTop: "10px" }}
              toolbarStyle={{ borderBottom: "1px solid lightgrey" }}
              onEditorStateChange={this.onEditorStateChange}
            />
          </div>
        </div>

        <div className="btn">
          <Button variant="outlined" color="primary" onClick={() => this.props.history.goBack()}>
            {t('BACK')}
          </Button>
          <Button variant="outlined" color="primary" onClick={() => this.updateRecording()}>
            <SaveIcon />
            {t('SAVE')}
          </Button>
          <Button variant="outlined" color="primary" onClick={() => this.copyTextToClipboard(editorState.getCurrentContent().getPlainText())}>
            <FileCopyIcon />
            {t('COPY_TO_CLIPBOARD')}
          </Button>
        </div>
      </div>
    )
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(JSON.parse(window._env.REACT_APP_USE_AUDIO_HOTKEYS) ? hotkeys(RecordingDetail) : RecordingDetail)));

