import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';

import { withTranslation, WithTranslation } from 'react-i18next';

import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import AsrBox from './AsrBox/AsrBox'
import TranslationBox from './TranslationBox/TranslationBox'
import BrowserRecording from './BrowserRecording/BrowserRecording'

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const mapDispatchToProps = () => ({});

const mapStateToProps = (state) => ({
  frenchActivated: state.translation.frenchActivated,
  englishActivated: state.translation.englishActivated,
  user: state.user,
});

interface IState {
  user?: String,
  showSettings?: any
  showToast?: boolean,
  toastText?: any,
  toastIcon?: any,
  severity?: any
}

interface IProp extends WithTranslation {
  user:any,
  serverMessage?:any
  stopRecording: any
  websocketUrl: any
  createNewWebsocketConnection: any
}

class App extends Component<IProp, IState> {
  constructor(props) {
    super(props);
    this.state = {
      showSettings: false,
      showToast: false,
      severity: '',
    };
  }
  
  handleClose = () => {
    this.setState({ showToast: false })
  };

  renderToast = () => {
    const vertical = 'top';
    const horizontal = 'center';

    return (
      this.state.showToast &&
      <Snackbar
        open={this.state.showToast}
        autoHideDuration={2000}
        onClose={this.handleClose}
        anchorOrigin={{ vertical, horizontal }}
        key={`${vertical},${horizontal}`}
      >
        <MuiAlert icon={this.state.toastIcon} elevation={6} variant="filled" onClose={this.handleClose} severity={this.state.severity}>
          {this.state.toastText}
        </MuiAlert>
      </Snackbar>
    )
  }

  render() {
    const { t } = this.props;

    return (
      <Container>
        {
          this.renderToast()
        }
        <Row className="row-wrapper">
            <Col xs={12} md={12} lg={12} xl={{ offset: 1, span: 10 }}>
              <AsrBox />
            </Col>
        </Row>
        <Row className="row-wrapper">
          {
          this.props.user.germanActivated &&
            <Col xs={12} sm={12} md={12} lg={6}>
              <TranslationBox key="DE" lang="DE" headline={t("GERMAN")} />
            </Col>
          }
          {
          this.props.user.englishActivated &&
            <Col xs={12} sm={12} md={12} lg={6}>
              <TranslationBox key="EN" lang="EN" headline={t("ENGLISH")} />
            </Col>
          }
          {
          this.props.user.frenchActivated &&
            <Col xs={12} sm= {12} md={12} lg={6}>
              <TranslationBox key="FR" lang="FR" headline={t("FRENCH")} />
            </Col>
          }
        </Row>
        <BrowserRecording />
      </Container>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(App));

