import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTranslation } from 'react-i18next';

import { getUsers, deleteUser, updateUser } from '../../../actions/userAction'
import { v4 as uuidv4 } from 'uuid';

import './UserTable.scss';


const mapDispatchToProps = (dispatch) => ({
    getUsers: () => dispatch(getUsers()),
    deleteUser: (id) => dispatch(deleteUser(id)),
    updateUser: (id, data) => dispatch(updateUser(id, data)),
});
  
const mapStateToProps = (state) => ({
    user: state.user
});

interface IProp{
  updateUser?: any
  data?: any
  deleteUser?: any
}

function UserTable(props : IProp) {
  const [open, setOpen] = React.useState(false);
  const [data, setdata] = React.useState<any>({});

  const { t } = useTranslation();

  const handleClickOpen = (user: any) => {
    setdata({ ...user});
    setOpen(true);
  };

  const handleChange = (event) => {
    let value: any;
    const { name, type } = event.target;

    if (type === 'checkbox') {
      value = event.target.checked
    } else {
      value = event.target.value
    }
    setdata((prevState) => ({ ...prevState ,[name]: value}));
  }

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (event: any) => {
    event.preventDefault();
    props.updateUser(data._id, data)
    setOpen(false);
  };
  
  const generateUuid = () => {
    setdata((prevState) => ({ ...prevState ,uuid : uuidv4()}));
  }

  return (
    <div>
    <div className="wrapper">
    {
      props.data.users.map((user: any) =>{
        return ( 
          <div className="userTable" key={user._id}>    
            <div className="userName">
              {user.name}
            </div>
            <div className="uuid">
              {user.uuid}
            </div>
            {
              window._env.REACT_APP_SEND_ASR_RESULTS_VIA_EMAIL === 'true' &&
              <div className="uuid">
                {user.email}
              </div>
            }
            <div className="uuid">
              {user.websocketUrl}
            </div>
            <div className="buttons">
            <Button className="btnDelete" onClick={ () => handleClickOpen(user)} variant="outlined" color="primary" type="submit">Edit</Button>
            <Button className="btnDelete" onClick={() => props.deleteUser(user._id)} variant="outlined" color="primary" type="submit">Delete</Button>
            </div>
          </div>
      );
     })
    }

    </div>
    <form className="userForm" onSubmit={() => onSubmit}>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" style={{backgroundColor: 'transparent'}}>
      <DialogTitle id="form-dialog-title">Edit User</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter new Name, UUID or WebsocketUrl
        </DialogContentText>
      <TextField
        autoFocus
        name="name"
        margin="dense"
        id="name"
        label="Name"
        type="text"
        value={data.name }
        fullWidth
        onChange={handleChange}
      />
      <TextField
        name="uuid"
        margin="dense"
        id="uuid"
        label="UUID"
        type="text"
        value={data.uuid }
        fullWidth
        onChange={handleChange}
      />
      <TextField
        name="websocketUrl"
        margin="dense"
        id="websocketUrl"
        label="Websocket Url"
        type="text"
        value={data.websocketUrl}
        fullWidth
        onChange={handleChange}
      />
      <TextField
        name="microphone"
        margin="dense"
        id="microphone"
        label="Microphone"
        type="text"
        value={data.microphone}
        fullWidth
        onChange={handleChange}
      />
      <TextField
        name="microphoneMobile"
        margin="dense"
        id="microphoneMobile"
        label="Microphone Mobile"
        type="text"
        value={data.microphoneMobile}
        fullWidth
        onChange={handleChange}
      />
      {
        window._env.REACT_APP_SEND_ASR_RESULTS_VIA_EMAIL === 'true' &&
        <TextField name="email" id="email" label="Email" fullWidth value={data.email} onChange={handleChange} />
      }
      {
        window._env.REACT_APP_SHOW_ASR_PREVIEW_MODE === 'true' &&
        <div>
          <FormControlLabel
            control={<Switch color="primary" name="showAsrPreviewText" checked={data.showAsrPreviewText} />}
            label={t('SHOW_ASR_PREVIEW_TEXT_LABEL')}
            labelPlacement="start"
            onChange={handleChange}
          />
        </div>
      }
      {
        window._env.REACT_APP_SEND_ASR_RESULTS_VIA_EMAIL === 'true' &&
        <div>
          <FormControlLabel
            control={<Switch color="primary" name="sendAsrResultsPerEmail" checked={data.sendAsrResultsPerEmail} />}
            label={t('SEND_ASR_RESULTS_PER_EMAIL_LABEL')}
            labelPlacement="start"
            onChange={handleChange}
          />
        </div>
      }
      <div>
        <FormControlLabel
          control={<Switch color="primary" name="dictateActivated" checked={data.dictateActivated} />}
          label="Dictate Mode"
          labelPlacement="start"
          onChange={handleChange}
        />
      </div>
      <Button onClick={generateUuid} variant="outlined" color="primary">Generate UUID</Button>
      </DialogContent>
      <DialogActions>
      <Button onClick={handleClose} variant="outlined" color="primary">
        Cancel
      </Button>
      <Button onClick={onSubmit} variant="outlined" color="primary" type="submit">
        Save
      </Button>
    </DialogActions>
    </Dialog>
  </form>
    </div>
  );
}

export default connect(mapStateToProps,mapDispatchToProps )(UserTable);