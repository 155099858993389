import axios from 'axios';
import getApiHost from "../getHostDomain";
import { getURLParams } from '../routing/getURLParams';

const apiDomain = getApiHost(window._env.NODE_ENV) + "/api";
const getToken = () => `Bearer ${sessionStorage.getItem('token')}`;

export const getRecordings = (userID: any) => {
    return axios.get(`${apiDomain}/recordings?userID=${userID}&${getURLParams()}`, {
        headers: {
            'Authorization': getToken()
        }
    });
};

export const getRecordingsByAdmin = (searchParams: any) => {
    Object.keys(searchParams).forEach(key => searchParams[key] === "" && delete searchParams[key]);
    const queryParams = new URLSearchParams(searchParams).toString();
    

    return axios.get(`${apiDomain}/recordings?${queryParams}`, {
        headers: {
          'Authorization': getToken()
        }
      });  
};

export const getIncrementalRecordingNumber = (userID: any) => {
    return axios.get(`${apiDomain}/recordings/IncrementalRecordingNumber/?userID=${userID}&${getURLParams()}`);
};

export const getSearchParams = () => {
    return axios.get(`${apiDomain}/recordings/searchParams/`);
};

export const saveRecording = (newRecording: any) => {
    return axios.post(`${apiDomain}/recordings/?${getURLParams()}`, newRecording);
};

export const getRecordingById = (id: any, userID: any) => {
    return axios.get(`${apiDomain}/recordings/${id}?userID=${userID}&${getURLParams()}`);
};

export const updateRecording = (id: any, updatedRecording: any) => {
    return axios.put(`${apiDomain}/recordings/${id}?${getURLParams()}`, updatedRecording);
};

export const deleteRecording = (id: any, userID: any) => {
    return axios.delete(`${apiDomain}/recordings/${id}?userID=${userID}&${getURLParams()}`);
};

export default {
    getRecordings,
    saveRecording,
    getRecordingById,
    updateRecording,
    deleteRecording,
    getIncrementalRecordingNumber,
    getRecordingsByAdmin,
    getSearchParams
}