import React from 'react';

import './EnhancedTableToolbar.scss';

import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import { connect } from 'react-redux';
import { deleteRecording, getRecordings } from '../../../../actions/recordingAction'
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';


const mapDispatchToProps = (dispatch) => ({
    deleteRecording: (id, userID) => dispatch(deleteRecording(id, userID)),
    getRecordings: (userID) => dispatch(getRecordings(userID)),
});

const mapStateToProps = (state) => ({
    user: state.user
});

interface IProp{
    selectedRecording?:any,
    numSelected?:any,
    deleteRecording?:any,
    getRecordings?:any,
    user: any,
    setSelected?:any,
}

const EnhancedTableToolbar = (props:IProp) => {
    const { numSelected } = props;
    const { selectedRecording } = props;
    const [open, setOpen] = React.useState(false);
    const [ showToast, setShowToast] = React.useState(false);
    const [ severity, setSeverity ] = React.useState('' as any);
    const { t } = useTranslation();

    const deleteRecordings = () => {
        // todo: create api for multiple deletes of recordings
        const deletedRecordings = selectedRecording.map((id) => props.deleteRecording(id, props.user._id));

        Promise.all(deletedRecordings)
        .then(() => {
              setSeverity('success');
              setShowToast(true);
        })
        .catch(() => {
            setSeverity('error');
            setShowToast(true);
        })
        .finally(() => {
            props.setSelected([])
            props.getRecordings(props.user._id)
            setOpen(false);
        })
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseToast = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setShowToast(false);
    };
    
    const renderToast = () => {
        const vertical = 'top';
        const horizontal = 'center';
    
        const toastText = severity === 'success' ?  t('DELETETOAST') : t('DELETETOASTCANCEL');
    
        return (
          showToast &&
            <Snackbar
              open={showToast}
              autoHideDuration={2000}
              onClose={handleCloseToast}
              anchorOrigin={{ vertical, horizontal }}
              key={`${vertical},${horizontal}`}
            >
              <MuiAlert elevation={6} variant="filled" onClose={() => handleCloseToast} severity={severity}>
                {toastText}
              </MuiAlert>
            </Snackbar>
        )
    }

    return (
        <div>
            {
                renderToast()
            }
            <Typography className="title" variant="h5" id="tableTitle" component="div">
                {t('RECORDINGS')}
            </Typography>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t('DELETERECORDINGS')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('DELETETEXT')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t('CANCEL').toUpperCase()}
                </Button>
                <Button onClick={deleteRecordings} color="primary" autoFocus>
                    <DeleteIcon />
                    {t('DELETE').toUpperCase()}
                </Button>
                </DialogActions>
            </Dialog>
            {
                numSelected > 0 &&
                <Toolbar
                    className="root highlight"
                >
                    <Typography className="title" color="inherit" variant="h6" component="div">
                        {numSelected} {t('SELECTED')}
                        </Typography>
                    <Tooltip title="Delete">
                        <IconButton aria-label="delete" onClick={handleClickOpen}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            }
        </div>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(EnhancedTableToolbar);