import React from 'react';
import { Route } from "react-router-dom";
import { connect } from 'react-redux';

import SignIn from './components/SignIn/SignIn';
import CircularProgress from '@material-ui/core/CircularProgress';

import { login, checkAuth } from './actions/userAction'

interface IProp {
    component?:any,
    location?: any,
    path?:any,
    exact?:any,
    children?:any,
    login: any,
    checkAuth: any
}

interface IState {
    errorMessage?: string,
    isAuthenticated?: boolean,
    loading?: boolean
}

const mapDispatchToProps = (dispatch: any) => ({
    login: (username: string, password: string) => dispatch(login(username, password)),
    checkAuth: () => dispatch(checkAuth())
});

class AdminRoute extends React.Component<IProp, IState> {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: "",
            loading: true,
        }
    }
    
    componentDidMount() {
        this.props.checkAuth().then(resp => {
            this.setState({isAuthenticated: true, loading: false})
        }).catch(error => {
            this.setState({isAuthenticated: false, loading: false})
        })
    }
    
    
    signIn = (username, password) => {
        this.props.login(username, password)
            .then(() => {
                this.setState({errorMessage: ""});
                this.setState({isAuthenticated: true});
            })
            .catch((error) => {
                this.setState({errorMessage: "INVALID_USERNAME_OR_PASSWORD"})
                this.setState({isAuthenticated: false});
            })
    }
    
    render() {
        const { component: Component, ...rest } = this.props;
        
        if (this.state.loading) {
            return( 
                <div className="loading">
                    <CircularProgress size="100px" />
                </div>
            );        
        }
        
        if (this.state.isAuthenticated === false) {
            return <SignIn signIn={this.signIn} errorMessage={this.state.errorMessage} />
        }

        return (
            <Route {...rest} render={props => (
                <div>
                    <Component {...this.props} />
                </div>
                )}
            />
        )
    }
}

export default connect(null, mapDispatchToProps)(AdminRoute);