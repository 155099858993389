
interface CardProps {
    result: string,
    finalResults: any,
    tempResults: any,
    websocketOpen: boolean,
    recordingRunning: boolean,
    id: string,
 }

const initialState : CardProps  = {
  result: 'EMPTY',
  finalResults: [],
  tempResults: [],
  websocketOpen: false,
  recordingRunning: false,
  id: '',
};

export default (state = initialState, action: any) => {
    switch (action.type) {
        case 'WEBSOCKET_MESSAGE_RECEIVED':
            let {hypotheses, final, id} = action.payload;
            const finalResults = state.finalResults as any;
            const result  = hypotheses[0];

            if (final === true) {
                finalResults.push(result)
                return {
                    ...state,
                    id,
                    finalResults,
                    tempResults: []
                }
            }

            return {
                ...state,
                id,
                tempResults: [{...result, temp: true}]
            }
        case 'SERVER_ERROR_MESSAGE_RECEIVED':
            return {
                ...state,
                errorExits: true,
                serverMessage: action.payload,
            }
        case 'WEBSOCKET_CONNECTION_OPEN':
            return {
                ...state,
                websocketOpen: true,
                errorExits: false,
                serverMessage: ''
            }
        case 'WEBSOCKET_CONNECTION_CLOSED':
            const { wasClean, reason } = action.payload;
            if (wasClean === true) {
                return {
                    ...state,
                    websocketOpen: false,
                }
            }
            return {
                ...state,
                errorExits: true,
                websocketOpen: false,
                serverMessage: reason
            }
        case 'CLEAR_ERROR_MESSAGE_RECEIVED':
            return {
                ...state,
                errorExits: false,
                serverMessage: ''
            }
        case 'CLEAR_MESSAGES':
            return {
                ...state,
                finalResults: [],
                tempResults: [],
                id: '',
            }
        default:
            return state
    }
}
