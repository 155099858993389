import React from 'react';
import { useHistory } from "react-router-dom";
import moment from 'moment'
import { useTranslation } from 'react-i18next';

import 'moment/locale/de';
import 'moment/locale/fr';

import './TableRecordings.scss';

import EnhancedTableHead from './EnhancedTableHead/EnhancedTableHead';
import EnhancedTableToolbar from './EnhancedTableToolbar/EnhancedTableToolbar';

import Checkbox from '@material-ui/core/Checkbox';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import { getURLParams } from '../../../routing/getURLParams';


function descendingComparator(a, b, orderBy) {
  console.log('orderBy', orderBy)
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
  
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
  
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface IProp{
  data?:any,
  user: any
}

export default function EnhancedTable(props: IProp) {
    let history = useHistory();
    // const classes = useStyles();
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('recordingDate');
    const [selected, setSelected] = React.useState([] as any);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };
  
    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelecteds = props.data.map((n) => n._id);
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    };
  
    // todo: refactor code
    const handleClick = (event, name) => {
        if (event.target.type !== 'checkbox'){
            openRecordingPage(name)
        }
        
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
        );
        }

        setSelected(newSelected);
    };
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
  
    const isSelected = (name) => selected.indexOf(name) !== -1;
  
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, props.data.length - page * rowsPerPage);

    const openRecordingPage = (_id) => {
      history.push(`/recordings/${_id}?`+ getURLParams());
    };

    const { i18n } = useTranslation();

    const getCurrentLng = () => i18n.language || window.localStorage.i18nextLng || '';

    const formatDate = (recordingDate) => {
      moment.locale(getCurrentLng())
      return moment(recordingDate).format('LLLL')
    }

  
    return (
      <div className="root">
        <EnhancedTableToolbar numSelected={selected.length} selectedRecording={selected} setSelected={setSelected}/>
        <TableContainer>
          <Table
            className="table"
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={props.data.length}
            />
            <TableBody>
              {stableSort(props.data, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((recording: any, index:number) => {
                  const isItemSelected = isSelected(recording._id);
                  const labelId = `enhanced-table-checkbox-${index}`;
  
                  return (
                    <TableRow
                      className="row"
                      hover
                      onClick={(event) => handleClick(event, recording._id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={recording._id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox" style={{ verticalAlign: 'middle' }}>
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none" style={{ verticalAlign: 'middle' }}>
                        {recording.title}
                      </TableCell>
                      <TableCell align="center" style={{ verticalAlign: 'middle' }}>{formatDate(recording.recordingDate)}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={props.data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    );
}