import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Elevation } from "@blueprintjs/core";

import { getTranslation } from '../../../actions/translationAction';

import './Translation.scss';

const mapStateToProps = (state, ownProps) => ({
    translation: state.translation[ownProps.lang] || '',
    textToTranslate: getFinalText(state.recorder.finalResults),
    user: state.user
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    getTranslation: (text) => dispatch(getTranslation(text, ownProps.lang)),
});

const getFinalText = (finalResults) => {
  return finalResults.map(hypothese => hypothese.transcript).join(' ')
}
interface IState {
  element?: any
}

interface IProp {
  lang?: any
  headline?: any
  textToTranslate?: any
  getTranslation?: any
  translation?: any
  user?: any
}
class TranslationBox extends Component<IProp, IState> {
  updateScroll = () => {
    const boxID = `${this.props.lang}-box`
    const element: any= document.getElementById(boxID);
    element.scrollTop = element.scrollHeight;
  }
  
  componentDidUpdate(prevProps) {
    if (this.props.textToTranslate !== prevProps.textToTranslate) {
      this.props.getTranslation(this.props.textToTranslate);
    }

    if (this.props.translation !== prevProps.translation) {
      this.updateScroll();
    }
  }

  componentDidMount() {
    this.props.getTranslation(this.props.textToTranslate);
  }

  render() {
    const sentences = this.props.translation.match( /[^.!?]+[.!?]+/g ) || [];

    return (
      <div className="wrapper">
       <h3 style={{fontSize: `${this.props.user.fontSize}px`}} >{this.props.headline}</h3>
       <Card id={`${this.props.lang}-box`} className="language-box" interactive={false} elevation={Elevation.FOUR}>
          {
            sentences.map((sentence, key) => {
              return (
                <p style={{fontSize: `${this.props.user.fontSize}px`}} key={key}>{sentence}</p>
              )
            })
          }
        </Card>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TranslationBox);
