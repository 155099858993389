import React, { Component } from 'react';
import './PageNotFound.scss';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import { withTranslation, WithTranslation } from 'react-i18next';
import { i18n } from 'i18next';


interface IState {
}

interface IProp {
  i18n: i18n,
  tReady: boolean,
  t: any
}

class AdminSection extends Component<IProp, IState, WithTranslation>{
  

  render() {
    const { t } = this.props;
    return (
      <div className="nomatchPage">
      <div className="icon">
        <SentimentVeryDissatisfiedIcon style={{ fontSize: 200 }} />
      </div>
      <h2>{t('PAGENOTFOUND')}</h2>
      </div>
    );
  }
}

export default withTranslation()(AdminSection);
