import translationAPI from '../api/translation-api'

export const getTranslation = (text: any, target_lang: any ) => {
    return {
        type: 'GET_TRANSLATION',
        meta: { target_lang },
        payload: translationAPI.getTranslation(text, target_lang),
    }
}

export const handleModeChange = (mode: any ) => {
    return {
        type: 'HANDLE_MODE_CHANGE',
        payload: { mode },
    }
}

