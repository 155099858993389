import React from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import './SwipeableTemporaryDrawer.scss';
import recappLogo from '../../static/recapp-logo.png';

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import FolderIcon from '@material-ui/icons/Folder';
import MicIcon from '@material-ui/icons/Mic';
import SettingsIcon from '@material-ui/icons/Settings';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CachedIcon from '@material-ui/icons/Cached';
import { getURLParams } from '../../routing/getURLParams'
const mapStateToProps = (state: any) => ({
  user: state.user
});

interface IProp{
  children?:any,
  user:any,
}

function SwipeableTemporaryDrawer(props: IProp) {
  const [state, setState] = React.useState({
    showToast: false,
    severity: '',
    text: '',
    top: false,
    left: false,
    bottom: false,
    right: false,
    title: '',
  });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleLanguageMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageMenuClose = () => {
    setAnchorEl(null);
  };

  const { t, i18n } = useTranslation();
  const getCurrentLng = () => i18n.language || window.localStorage.i18nextLng || '';

  const vertical = 'top';
  const horizontal = 'center';

  const { children } = props;

  const handleClose = (event, reason) => {
    setState({ ...state, showToast: false })
    if (reason === 'clickaway') {
      return;
    }
  };

  const toggleDrawer = (anchor, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
    handleLanguageMenuClose()
  };

  const reloadPage = () => {
    window.location.reload();
  }

  const list = () => (
    <div
      className="list"
      role="presentation"
      onClick={toggleDrawer('left', false)}
      onKeyDown={toggleDrawer('left', false)}
    >
      <List>
        <ListItem button key={"Dictate"} component={Link} to={{ pathname: '/', search: getURLParams()}}>
          <ListItemIcon>
            <MicIcon />
          </ListItemIcon>
          <ListItemText primary={t('HOME')} />
        </ListItem>
        <ListItem button key={"Recordings"} component={Link} to={{ pathname: '/recordings', search: getURLParams()}}>
          <ListItemIcon>
            <FolderIcon />
          </ListItemIcon>
          <ListItemText primary={t('RECORDINGS')} />
        </ListItem>
        <ListItem button key={"Settings"} component={Link} to={{ pathname: '/settingsPage', search: getURLParams()}}>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary={t('SETTINGS')} />
        </ListItem>
      </List>
    </div>
  );

  return (
    <div>
      <div>
        <Snackbar
            open={state.showToast}
            autoHideDuration={2000}
            onClose={handleClose}
            anchorOrigin={{ vertical, horizontal }}
            key={`${vertical},${horizontal}`}
        >
          <MuiAlert elevation={6} variant="filled" onClose={() => handleClose} severity={'error'}>
            {state.text}
          </MuiAlert>
        </Snackbar>
        <AppBar
          position="static"
          color="transparent"
          className="appBar"
        >
          <img className="logo" width="120px" height="32px" src={recappLogo} alt="recapp-logo" />
          <Toolbar>
            <React.Fragment key={'left'}>
              <IconButton onClick={toggleDrawer('left', true)} edge="start" color="inherit" aria-label="menu">
                <MenuIcon />
              </IconButton>
              <SwipeableDrawer
                anchor={'left'}
                open={state['left']}
                onClose={toggleDrawer('left', false)}
                onOpen={toggleDrawer('left', true)}
              >
                {list()}
              </SwipeableDrawer>
            </React.Fragment>
          </Toolbar>
          <div className="btn-container">
            <IconButton onClick={reloadPage} color="inherit" aria-label="reload">
              <CachedIcon />
            </IconButton>
            <Button aria-controls="language-menu" aria-haspopup="true" onClick={handleLanguageMenuClick}>
              {getCurrentLng()}
            </Button>
            <Menu
              id="language-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleLanguageMenuClose}
            >
              <MenuItem onClick={() => changeLanguage('de')}>DE</MenuItem>
              <MenuItem onClick={() => changeLanguage('fr')}>FR</MenuItem>
              <MenuItem onClick={() => changeLanguage('en')}>EN</MenuItem>
            </Menu>
          </div>
        </AppBar>
      </div>
      {children}
    </div>
  );
}

export default connect(mapStateToProps, {})(SwipeableTemporaryDrawer)

