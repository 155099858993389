export function getURLParams() {
    const queryString = window.location.search; 
    const urlParams = new URLSearchParams(queryString);
    const entries: any = urlParams.entries();
    const params = paramsToObject(entries); 
    Object.keys(params).forEach(key => params[key] === "null"  && delete params[key]);
    const queryParams = new URLSearchParams(params).toString();
    
    return queryParams
}

function paramsToObject(entries) {
    const result = {}
    for(const [key, value] of entries) { 
      result[key] = value;
    }
    return result;
}