import _ from 'lodash';
import axios from 'axios';
import getApiHost from "../getHostDomain";
import { getURLParams } from '../routing/getURLParams';

const apiDomain = getApiHost(window._env.NODE_ENV) + "/api";
const getToken = () => `Bearer ${sessionStorage.getItem('token')}`;

const getUserByUuid = (uuid: any) => {
    return axios.get(`${apiDomain}/users/${uuid}`);
};

const createUser = (user: any) => {
    return axios.post(`${apiDomain}/users`, user, {
        headers: {
          'Authorization': getToken()
        }
      });
};

const getUsers = () => {
    return axios.get(`${apiDomain}/users`, {
        headers: {
          'Authorization': getToken()
        }
      });
}

const deleteUser = (id: any) => {
    return axios.delete(`${apiDomain}/users/${id}`, {
        headers: {
          'Authorization': getToken()
        }
      });
}

const updateUser = (id: any, updatedUser: any) => {
    return axios.put(`${apiDomain}/users/${id}?${getURLParams()}`, updatedUser, {
        headers: {
          'Authorization': getToken()
        }
      });
}

const login = (username: string, password: string) => {
  return new Promise(async(resolve, reject) => {
    const resp = await axios.post(`${apiDomain}/auth/login`, {username, password})
    try {
      const accessToken = _.get(resp, 'data');
      if (accessToken) {
        sessionStorage.setItem("token", accessToken);
        resolve(resp)
      } else {
        reject("Access Token not found");
      }
    } catch (error) {
      reject(error)
    }
  });
};

const checkAuth = () => {
  return axios.get(`${apiDomain}/auth/checkAuth`, {
    headers: {
      'Authorization': getToken()
    }
  });
}

export default {
    getUserByUuid,
    createUser,
    getUsers,
    deleteUser,
    updateUser,
    login,
    checkAuth
}