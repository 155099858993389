import React, { Component } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import UserTabel from './UserTable/UserTable';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withTranslation, WithTranslation } from 'react-i18next';
import { i18n } from 'i18next';

import { createUser } from '../../actions/userAction';
import { getUsers } from '../../actions/userAction';

import './AdminPage.scss';

interface IState {
  user?: String,
  name?: String, 
  uuid?: String, 
  email?: String,
  websocketUrl?: String,
  showToast?: boolean ,
  dictateActivated?: boolean ,
  showAsrPreviewText?: boolean ,
  sendAsrResultsPerEmail?: boolean ,
  severity?: any,
}

interface IProp {
  createUser?:any,
  getUsers?:any
  user?:any,
  AdminPage?:any,
  search?: String
  loading?: boolean,
  i18n: i18n,
  tReady: boolean,
  t: any
}

const mapDispatchToProps = {
  createUser: (user:any) => createUser(user),
  getUsers: () => getUsers(),
};

const mapStateToProps = (state:any) => ({
  user: state.user
});


class AdminPage extends Component<IProp, IState, WithTranslation>{
  constructor(props: any) {
    super(props);
    this.state = { 
      name : '', 
      uuid: '', 
      email: '',
      websocketUrl: window._env.REACT_APP_WEBSOCKET_URL,
      showToast: false,
      showAsrPreviewText: true,
      sendAsrResultsPerEmail: true,
      severity: '',
      dictateActivated:  window._env.REACT_APP_DICTATE_MODE === 'true' ? true : false,
    };
  }

  handleChange = (event:any) => {
    let value: any;
    const { name, type } = event.target;

    if (type === 'checkbox') {
      value = event.target.checked
    } else {
      value = event.target.value
    }

    this.setState({ [name] : value});
  }

  handleSubmit = (event:any) => {
    event.preventDefault();
    this.props.createUser(this.state).then(resp => {
      this.setState({ showToast: true, severity: 'success'})
    }).catch(() => {
      this.setState({ showToast: true, severity: 'error' })
    }) ;
  }

  handleClose = (event:any, reason:any) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ showToast: false })
  };


  generateUuid = () => {
    this.setState({ uuid : uuidv4()});
  }

  componentDidMount(){
    this.props.getUsers();
  }


  render() {
    const vertical: any = 'top';
    const horizontal: any = 'center';
    const  {  t } : any = this.props;
    const toastText: any = this.state.severity === 'success' ? t('SAVEUSERTOAST') : t('SAVENOTUSERTOAST');
    

    return (
      <div>
      <form className="userForm" onSubmit={this.handleSubmit}>
        {
        this.state.showToast &&
          <Snackbar
            open={this.state.showToast}
            autoHideDuration={2000}
            onClose={this.handleClose}
            anchorOrigin={{ vertical, horizontal }}
            key={`${vertical},${horizontal}`}
          >
            <MuiAlert elevation={6} variant="filled" onClose={() => this.handleClose} severity={this.state.severity}>
              {toastText}
            </MuiAlert>
          </Snackbar>
        }
        <TextField name="uuid" id="uuid" label="UUID" value={this.state.uuid} onChange={this.handleChange} />
        <TextField name="name" id="name" label="Name" value={this.state.name} onChange={this.handleChange} />
        {
          window._env.REACT_APP_SEND_ASR_RESULTS_VIA_EMAIL === 'true' &&
          <TextField name="email" id="email" label="Email" value={this.state.email} onChange={this.handleChange} />
        }
        <TextField name="websocketUrl"  id="websocketUrl" label="Websocket Url" value={this.state.websocketUrl} onChange={this.handleChange} />
        <div className="checkbox">
          <FormControlLabel
            control={<Switch color="primary" name="dictateActivated" checked={this.state.dictateActivated} />}
            label="Dictate Mode"
            labelPlacement="start"
            onChange={this.handleChange}
          />
        </div>
        {
          window._env.REACT_APP_SHOW_ASR_PREVIEW_MODE === 'true' &&
          <div className="checkbox">
            <FormControlLabel
              control={<Switch color="primary" name="showAsrPreviewText" checked={this.state.showAsrPreviewText} />}
              label={t('SHOW_ASR_PREVIEW_TEXT_LABEL')}
              labelPlacement="start"
              onChange={this.handleChange}
            />
          </div>
        }
        {
          window._env.REACT_APP_SEND_ASR_RESULTS_VIA_EMAIL === 'true' &&
          <div className="checkbox">
            <FormControlLabel
              control={<Switch color="primary" name="sendAsrResultsPerEmail" checked={this.state.sendAsrResultsPerEmail} />}
              label={t('SEND_ASR_RESULTS_PER_EMAIL_LABEL')}
              labelPlacement="start"
              onChange={this.handleChange}
            />
          </div>
        }
        <Button
            type="submit"
            variant="outlined"
            size="large"
            disabled={this.state.uuid === '' || this.state.name === ''}
            startIcon={<SaveIcon />}
          >
             {t('SAVEUSER').toUpperCase()}
        </Button>
        <Button
            type="button"
            variant="outlined"
            size="large"
            startIcon={<VpnKeyIcon />}
            onClick={this.generateUuid}
          >
            {t('GENERATEUUID').toUpperCase()}
        </Button>
      </form>
      <UserTabel data={this.props.user}  />
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AdminPage));


