import React from 'react';
import {
    Route,
    Redirect
} from "react-router-dom";
import { connect } from 'react-redux';
import { getUserByUuid } from './actions/userAction'
import CircularProgress from '@material-ui/core/CircularProgress';

import './PrivateRoute.scss';

const mapStateToProps = (state: any) => ({
    user: state.user
});

const mapDispatchToProps = (dispatch: any) => ({
    getUserByUuid: (uuid: any) => dispatch(getUserByUuid(uuid))
});

interface IState {

}
  
interface IProp {
    getUserByUuid?:any,
    component?:any,
    location?: any,
    path?:any,
    exact?:any,
    children?:any
}
class PrivateRoute extends React.Component<IProp, IState> {
    
    state = {
        loading: true,
        isAuthenticated: false,
    }

    componentDidMount() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const uuid = urlParams.get('uuid');
        if (uuid) {
            this.props.getUserByUuid(uuid)
            .then(() => {
                this.setState({
                    loading: false,
                    isAuthenticated: true,
                });
            })
            .catch(() => {
                this.setState({
                    loading: false,
                    isAuthenticated: false,
                });
            });
        } else {
            this.setState({ loading: false });
        }
    }
    render() {
        const { component: Component, ...rest } = this.props;

        if (this.state.loading) {
            return( 
                <div className="loading">
                <CircularProgress size="100px"   />
                </div>
            );        
        }
        if (this.state.isAuthenticated === false) {
            return <Redirect to={{ pathname: '/401', state: { from: this.props.location } }} /> 
        }

        return (
            <Route {...rest} render={props => (
                <div>
                    <Component {...this.props} />
                </div>
                )}
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
