import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import configureStore from './store';


import './components/HomePage/App.scss';

import './i18next';

import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import dotenv from 'dotenv';
import dotenvExpand from 'dotenv-expand';

import './index.scss';
import App from './components/HomePage/App';
import * as serviceWorker from './serviceWorker';

import PrivateRoute from './PrivateRoute'
import RecordingList from './components/RecordingsPage/RecordingList';
import SwipeableTemporaryDrawer from './components/SwipeableTemporaryDrawer/SwipeableTemporaryDrawer';
import RecordingDetail from './components/RecordingDetailPage/RecordingDetail';
import AdminPage from './components/AdminPage/AdminPage';
import PageNotFound from './components/PageNotFound/PageNotFound';
import SettingsPage from './components/SettingsPage/SettingsPage';
import UnauthorizedPage from './components/UnauthorizedPage/UnauthorizedPage';
import SearchRecordingsPage from './components/SearchRecordingsPage/SearchRecordingsPage';
import AdminRoute from './AdminRoute';

declare global {
    interface Window {
        _env: any;
    }
}

const store = configureStore();

const myEnv = dotenv.config()
dotenvExpand(myEnv)

ReactDOM.render(
    <Suspense fallback={(<div></div>)}>
    <Provider store={store}>
        <Router>
            <SwipeableTemporaryDrawer>
                <Switch>
                    <PrivateRoute exact path="/" component={App} />
                    <PrivateRoute exact path="/recordings" component={RecordingList} />
                    <PrivateRoute exact path="/recordings/:id" component={RecordingDetail} />
                    <PrivateRoute exact path="/settingsPage" component={SettingsPage} />
                    <AdminRoute exact path="/searchRecordings" component={SearchRecordingsPage} />
                    <AdminRoute exact path="/adminPage" component={AdminPage} />
                    <Route exact path="/401">
                        <UnauthorizedPage />
                    </Route>
                    <Route>
                        <PageNotFound />
                    </Route>
                </Switch>
            </SwipeableTemporaryDrawer>
        </Router>
    </Provider>
    </Suspense>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

export default store;

