import { combineReducers } from 'redux';
import recorder from './recorder';
import translation from './translation';
import recordings from './recordings';
import user from './user';

export default combineReducers({
    recorder,
    translation,
    recordings,
    user
});