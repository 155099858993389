import React, { Component } from 'react';

import TableRecordings from './TableRecordings/TableRecordings';
import './RecordingList.scss'
import { changeRecordingTitle, getRecordings } from '../../actions/recordingAction'
import { connect } from 'react-redux';

const mapDispatchToProps = (dispatch) => ({
  changeRecordingTitle: () => dispatch(changeRecordingTitle()),
  getRecordings: (userID) => dispatch(getRecordings(userID))
});

const mapStateToProps = (state) => ({
  recordings: state.recordings.recordings,
  user: state.user
});

interface IState {
}

interface IProp {
  user:any,
  recordings?:any,
  getRecordings?:any 

}


class RecordingList extends Component<IProp, IState> {
  componentDidMount(){
    const userID = this.props.user._id
    this.props.getRecordings(userID);
  }

  render() {
    return (
      <div className="table-wrapper" style={{overflowX: "auto"}}> 
        <TableRecordings data={this.props.recordings} user={this.props.user} />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecordingList);