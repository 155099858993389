import React from "react";
import SearchPage from "./SearchPage/SearchPage"
import Typography from '@material-ui/core/Typography';
import "./SearchRecordingsPage.scss"
import { useTranslation } from "react-i18next";

function SearchRecordingsPage() {

  const { t } = useTranslation();

  return (
    <div>
        <div className="pageTitle">
          <Typography variant="h4" >
            {t('SEARCH_RECORDINGS')}
          </Typography>
        </div>
        <SearchPage />
    </div>
  );
}

export default SearchRecordingsPage;
