import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { WithTranslation, withTranslation } from 'react-i18next';
import Oscilloscope from 'oscilloscope';
import { Row, Col } from 'react-bootstrap';
import { serializeError } from 'serialize-error';
import moment from 'moment';
import { hotkeys } from 'react-keyboard-shortcuts'

import MediaRecorderPolyFill from "opus-media-recorder";
import { FaMicrophone, FaMicrophoneSlash } from 'react-icons/fa';
import SaveRecordingModal from '../SaveRecordingModal/SaveRecordingModal'
import MicIcon from '@material-ui/icons/Mic';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';


import { isMobile } from '../../../detectMobile' 

import {
    sendWebsocketMessage,
    createNewWebsocketConnection,
    closeWebsocketConnection,
    saveRecording,
    clearMessages,
    clearServerErrorMessage,
    getIncrementalRecordingNumber
} from '../../../actions/recordingAction';

import './BrowserRecording.scss';

const mapStateToProps = (state, ownProps) => ({
    websocketOpen: state.recorder.websocketOpen,
    tempResults: state.recorder.tempResults,
    audioID: state.recorder.id,
    errorExits: state.recorder.errorExits,
    serverMessage: state.recorder.serverMessage,
    user: state.user,
    websocketUrl: state.user.websocketUrl || window._env.REACT_APP_WEBSOCKET_URL,
    count: state.recordings.count,
    finalResults: state.recorder.finalResults.reduce((acc, current) => {
        if(acc.length > 0 && current.transcript.length > 0) {
          return acc + ' ' + current.transcript;
        }
        else {
          return acc + current.transcript;
        }
      }, ''),
});

const mapDispatchToProps = (dispatch) => ({
    sendWebsocketMessage: (audioBlob) => dispatch(sendWebsocketMessage(audioBlob)),
    createNewWebsocketConnection: (url) => dispatch(createNewWebsocketConnection(url)),
    closeWebsocketConnection: () => dispatch(closeWebsocketConnection()),
    saveRecording: (recording) => dispatch(saveRecording(recording)),
    clearMessages: () => dispatch(clearMessages()),
    clearServerErrorMessage: () => dispatch(clearServerErrorMessage()),
    getIncrementalRecordingNumber: (userID) => dispatch(getIncrementalRecordingNumber(userID))
});

// opus-media-recorder options
const workerOptions = {
    encoderWorkerFactory: function () {
        return new Worker(process.env.PUBLIC_URL + '/opus-media-recorder/encoderWorker.umd.js')
    },
    OggOpusEncoderWasmPath: process.env.PUBLIC_URL + '/opus-media-recorder/OggOpusEncoder.wasm',
    WebMOpusEncoderWasmPath: process.env.PUBLIC_URL + '/opus-media-recorder/WebMOpusEncoder.wasm',
};

class BrowserRecording extends Component<any, any, WithTranslation> {

    recorder: any;
    constructor(props) {
        super(props);
        this.state = {
            showWaveform: false,
            showToast: false,
            audio: null,
            microphoneId: '',
            microphoneLabel: isMobile() ? props.user.microphoneMobile : props.user.microphone,
            selectedMicrophone: undefined,
            openSaveDialog: _.isEmpty(props.finalResults) ? false : true,
            recordingName: '',
            toastText: '',
            severity: '',
            oscilloscope: undefined,
            defaultMicrophone: true,
            title: '',
            recordingRunning: false,
        };
    }

    hot_keys = {
        [window._env.REACT_APP_HOTKEY_RECORD_START]: {
            priority: 1,
            handler: () => this.openWebSocketConnection()
        },
        [window._env.REACT_APP_HOTKEY_RECORD_STOP]: {
            priority: 1,
            handler: () => this.stopRecording()
        },
        [window._env.REACT_APP_HOTKEY_RECORD_COPY_TO_CLIPBOARD]: {
            priority: 1,
            handler: () => this.copyAsrTextToClipboard()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // ensure that the websocket connection is established
        if (prevProps.websocketOpen === false && this.props.websocketOpen === true) {
            this.startRecording();
        }

        // error handling if web socket connection will be closed from server
        if (this.state.recordingRunning && prevProps.websocketOpen === true && this.props.websocketOpen === false) {
            this.stopRecording();
        }

        if (this.props.errorExits && this.state.showToast === false) {
            const  { t } : any = this.props;

            this.setState({
                showToast: true,
                toastText: t(this.props.serverMessage),
                severity: "error",
            });
            this.stopRecording();
        }
    }

    copyAsrTextToClipboard() {
        const { t }: any = this.props;

        const asrBox = document.getElementById('asr-box');
        if (asrBox) {
            navigator.clipboard.writeText(asrBox.innerText)
                .then(() => {
                    this.setState({ showToast: true, severity: 'success', toastText: t('COPY_TEXT_SUCCESSFULLY'), toastIcon: <FileCopyIcon />})
                }, (err) => {
                    this.setState({ showToast: true, severity: 'error', toastText: t('COPY_TEXT_UNSUCCESSFULLY') })
                });
        }
    }

    openWebSocketConnection = () => {
        if (this.state.recordingRunning) {
            this.setState({ showToast: true, severity: 'error', toastText: this.props.t('ERROR_RECORDING_IS_ALREADY_RUNNING') })
            return
        }
        this.props.createNewWebsocketConnection(this.props.websocketUrl)
    };

    getSupportedConstrains = (constrains: any) => {
        let supports = navigator.mediaDevices.getSupportedConstraints();
        
        let supportedContrains = {}

        for (const constrain in constrains) {
            if (supports[constrain]) {
                supportedContrains[constrain] = constrains[constrain]
            }
        }

        return supportedContrains;
    }

    startRecording = () => {
        this.setState({ recordingRunning: true })
        this.props.clearMessages();

        // if deviceId is undefined, default microphone will be taken
        const constraints = {
            sampleRate: 16000,
            deviceId: this.state.defaultMicrophone === false ? { exact: this.state.microphoneId } : undefined,
        };

        navigator.mediaDevices.getUserMedia({
            video: false,
            audio: constraints
        })
        .then( (stream) => {
            const { label } = _.get(stream.getAudioTracks(), '[0]', {})

            if (this.props.errorExits !== true) {
                this.setState({
                    audio: stream,
                    selectedMicrophone: label
                })
                const options = { mimeType: 'audio/webm; codecs=opus' }

                // @ts-ignore
                this.recorder = new MediaRecorderPolyFill(stream, options, workerOptions)

                this.recorder.addEventListener('dataavailable', this.sendAudioBlobToWebsocket);
                this.recorder.addEventListener('onerror', this.onErrorEventHandler);
                this.recorder.addEventListener('stop', this.stopRecording);
                this.recorder.start(250);

                if (this.props.user.showWaveform) {
                    this.startWaveformAnimation(stream);
                }
            }
        })
        .catch((error) => {
            this.setState({
                showToast: true,
                toastText: "Unable to start browser recording error: " + JSON.stringify(serializeError(error)),
                severity: "error",
            });

            this.stopRecording();
        })
    };

    onErrorEventHandler = (event) => {
        let error = event.error;
        let toastText;

        switch(error.name) {
            case 'InvalidStateError':
                toastText = "You can't record the video right " +
                    "now. Try again later.";
                break;
            case 'SecurityError':
                toastText = "Recording the specified source " +
                    "is not allowed due to security " +
                    "restrictions.";
                break;
            default:
                toastText = "A problem occurred while trying " +
                    "to record the video.";
                break;
        }

        this.setState({
            showToast: true,
            toastText: toastText + "error: " + error,
            severity: "error",
        });

        this.stopRecording();
    }

    stopRecording = async () => {
        if (!this.state.recordingRunning) {
            return
        }
        
        this.props.closeWebsocketConnection();

        this.setState({
            recordingRunning: false,
            selectedMicrophone: undefined
        })

        if (this.recorder && this.recorder.state !== 'inactive') {
            this.recorder.stop();
        }

        if (this.props.user.showWaveform) {
            this.stopWaveformAnimation();
        }
        
        if (this.props.errorExits === false) {
            this.setState({ title: await this.getRecordingTitle() })
            
            if (window._env.REACT_APP_SHOW_RECORDING_SAVE_MODAL === 'false') {
                this.handleSubmit({});
            } else {
                this.setState({ openSaveDialog: true })
            }
        }
    };

    sendAudioBlobToWebsocket = (stream) => {
        const audioBlob = stream.data;
        this.props.sendWebsocketMessage(audioBlob);
    };

    loadMicrophone = () => {
        if (this.state.microphoneLabel === undefined) {
            return
        }

        navigator.mediaDevices.getUserMedia({ audio: true })
        .then(() => {
            return navigator.mediaDevices.enumerateDevices()
        })
        .then((devices) => {
            const microphone = devices.find((micro) => micro.label === this.state.microphoneLabel)

            if (microphone) {
                this.setState({
                    microphoneId: microphone.deviceId,
                    microphoneLabel: microphone.label,
                    defaultMicrophone: false
                })
            }

            if (microphone === undefined && this.state.microphoneLabel !== undefined) {
                this.setState({
                    showToast: true,
                    toastText: "Saved user microphone not found, default microphone was selected instead",
                    severity: "error",
                    defaultMicrophone: true
                })
            }
        })
        .catch((error) => {
            this.setState({
                showToast: true,
                toastText: "Unable to access microphone list error: " + error,
                severity: "error",
            })
        })
    };

    handleSaveDialogClose = () => {
        this.setState({ ...this.state, openSaveDialog: false });
    };

    handleClose = ( reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({ showToast: false });
        this.props.clearServerErrorMessage()
    };

    // handle lock screen event on ios
    handleVisibilityChange = () => {
        const isAppHidden = document['hidden'];

        if (this.state.recordingRunning && isAppHidden) {
            this.stopRecording();
        }

        if (isAppHidden === false) {
            window.location.reload();
        }
    };

    beforeunload = (event) => {
        if (this.state.recordingRunning) {
            event.returnValue = false
            this.stopRecording();
        }
    }

    startWaveformAnimation = (stream) => {
        let canvasContainer: any = document.getElementById("oscilloscope-container");
        let canvas: any = document.getElementById('canvas');

        if (!canvas) {
            canvas = document.createElement('canvas');
            canvas.id = "waveformcanvas";
            canvas.style.width = "100%";
            canvas.style.height = "100px";
            canvasContainer.appendChild(canvas);
        }

        const audioContext = new AudioContext();

        // create source from html5 audio element
        const canvasAudioNode = audioContext.createMediaStreamSource(stream);

        // attach oscilloscope
        const scope = new Oscilloscope(canvasAudioNode, { fftSize: 32768 });

        // start default animation loop
        scope.animate(canvas.getContext("2d"))

        this.setState({
            oscilloscope: scope
        })
    }

    stopWaveformAnimation = () => {
        // Stop the animation loop started by the .animate() method.
        if (this.state.oscilloScope) {
            this.state.oscilloScope.stop();
        }

        const canvas: any = document.getElementById('waveformcanvas');
        if (canvas) {
            canvas.parentNode.removeChild(canvas);
        }
    };
    
    urlParams: any = new URLSearchParams(window.location.search);
    queryParams: any = {}
    
    handleSubmit = ({ title }:any) => {
        const userID = this.props.user._id
        const audioID = this.props.audioID;
        const text = this.props.finalResults
        
        const currentLang = this.props.i18n.language || window.localStorage.i18nextLng || 'de';

        for(const [key, value] of this.urlParams) {
            this.queryParams[key] = value;
        }
        
        const _title = title || this.state.title
        
        const newRecording = {
            title: _title,
            text,
            userID,
            recordingDate: new Date(),
            audioID,
            uiLanguage: currentLang,
            ...this.queryParams
        };

        this.props.saveRecording(newRecording)
        .then(() => {
            this.setState({ title: '', showToast: true, severity: 'success', toastText: this.props.t('RECORDINGSAVED'), openSaveDialog: false });
        })
        .catch((error) => {
            console.log("[BrowserRecording.tsx] recording could not be saved. ", error)
            this.setState({ title: '', showToast: true, severity: 'error', toastText: this.props.t('RECORDINGNOTSAVED') });
        })
    }
    
    getRecordingTitle = () => {
        if (window._env.REACT_APP_AUTO_RECORDING_TITLE === 'true') {
            return moment().format('DD-MM-YYYY HH:mm:ss')
            
        } else if (window._env.REACT_APP_USE_CUSTOM_RECORDING_TITLE === 'true') {
            return this.props.getIncrementalRecordingNumber(this.props.user._id)
                .then(() => {
                    const { coursename, quizname, question } = this.queryParams
                    return `${coursename}_${quizname}_${question}_${this.props.count}`;
                    
                })
        } else {
            return ''
        }
    }

    observeRecordingDevices = () => {
        navigator.mediaDevices.ondevicechange = () => {
            navigator.mediaDevices.enumerateDevices()
            .then((devices) => {
                const userMicrophone = devices.find((micro) => micro.label === this.state.microphoneLabel)

                if (userMicrophone === undefined && this.state.recordingRunning) {
                    this.stopRecording();
                }

                this.loadMicrophone();
            })
        }
    };

    componentDidMount() {
        this.loadMicrophone();
        this.observeRecordingDevices();
        window.addEventListener("beforeunload", this.beforeunload);
    }

    componentWillUnmount() {
        this.stopRecording()
        this.props.clearMessages();
        window.removeEventListener('beforeunload', this.beforeunload)
    }
    
    render() {
        const vertical = 'top';
        const horizontal = 'center';
        const  { t } : any = this.props;
        return (
            <Row className="row-wrapper">
                {
                    this.state.showToast &&
                    <Snackbar
                        open={this.state.showToast}
                        autoHideDuration={2000}
                        onClose={this.handleClose}
                        anchorOrigin={{ vertical, horizontal }}
                        key={`${vertical},${horizontal}`}
                    >
                        <MuiAlert elevation={6} variant="filled" onClose={this.handleClose} severity={this.state.severity}>
                            {this.state.toastText}
                        </MuiAlert>
                    </Snackbar>
                }
                <div className="microphone-container">
                    {
                        this.state.recordingRunning ?
                            <button className="recording-pulse-btn" onClick={this.stopRecording}>
                                <FaMicrophoneSlash size="30" />
                            </button>
                            :
                            <button className="recording-btn" onClick={this.openWebSocketConnection}>
                                <FaMicrophone size="30" />
                            </button>
                    }
                </div>
                <SaveRecordingModal open={this.state.openSaveDialog} submitRecording={this.handleSubmit} onClose={this.handleSaveDialogClose} preDefinedTitle={this.state.title} />
                <Col xs={12} md={12} lg={12} xl={{ offset: 1, span: 10 }}>
                    <div className="selectedMicrophoneContainer">
                        <MicIcon />
                        <span>
                            {
                                this.state.selectedMicrophone ?
                                    this.state.selectedMicrophone :
                                    this.state.defaultMicrophone ? t('DEFAULT_MICROPHONE') : this.state.microphoneLabel
                            }
                        </span>
                    </div>
                    {
                        this.props.user.showWaveform &&
                            <div id="oscilloscope-container"></div>
                    }
                </Col>
            </Row>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(JSON.parse(window._env.REACT_APP_USE_RECORD_HOTKEYS) ? hotkeys(BrowserRecording) : BrowserRecording));
