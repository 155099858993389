import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import './SaveRecordingModal.scss';
interface IProp{
  open?:any,
  onClose?:any,
  submitRecording: any,
  preDefinedTitle?: string
}

function SaveRecordingModal(props: IProp) {

    const [title, setTitle] = React.useState(props.preDefinedTitle);

    const { t } = useTranslation();
    
    useEffect(() => {
      setTitle(props.preDefinedTitle)
    }, [props.preDefinedTitle])

    const handleChange = (event) => {
      setTitle(event.target.value);
    };
    
    const submitRecording = () => {
      props.submitRecording({ title })
      setTitle("")
    }

    return (
      <div>
        <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
          <div className="dialog-content">
              {
                  window._env.REACT_APP_USE_CUSTOM_RECORDING_TITLE === 'true' ||
                  window._env.REACT_APP_AUTO_RECORDING_TITLE === 'true' ?
                      <>
                          <DialogTitle id="form-dialog-title">{t('SAVERECORDING')}</DialogTitle>
                          <DialogContent>
                              <p>{title}</p>
                          </DialogContent>
                      </>
                      :
                      <>
                          <DialogTitle id="form-dialog-title">{t('SAVERECORDING')}</DialogTitle>
                          <DialogContent>
                              <TextField
                                  autoFocus
                                  margin="dense"
                                  id="recording-title"
                                  label={t('ENTERTITLE')}
                                  fullWidth
                                  value={title}
                                  onChange={handleChange}
                              />
                          </DialogContent>
                      </>
              }
            <DialogActions>
              <Button onClick={props.onClose} color="primary">
               {t('CANCEL').toUpperCase()}
              </Button>
              <Button onClick={submitRecording} color="primary">
               {t('SAVE').toUpperCase()}
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      </div>
    );
  }

  export default SaveRecordingModal;
