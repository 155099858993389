import createWebSocket from '../Websocket'
import recordingAPI  from '../api/recording-api'

let ws: any;

const TAG_END_OF_SENTENCE = "EOS";

export const websocketConnectionOpen = (event: any ) => ({
    type: 'WEBSOCKET_CONNECTION_OPEN',
    payload: event,
});

export const websocketConnectionClosed = (event: any ) => ({
    type: 'WEBSOCKET_CONNECTION_CLOSED',
    payload: event,
});

export const receivedWebsocketMessage = (data: any ) => ({
    type: 'WEBSOCKET_MESSAGE_RECEIVED',
    payload: data,
});

export const receivedWebsocketErrorMessage = (error: any ) => ({
    type: 'WEBSOCKET_ERROR_MESSAGE_RECEIVED',
    payload: error,
});

export const receivedServerErrorMessage = (error: any ) => ({
    type: 'SERVER_ERROR_MESSAGE_RECEIVED',
    payload: error,
});

export const clearServerErrorMessage = () => ({
    type: 'CLEAR_ERROR_MESSAGE_RECEIVED',
});

export const sendWebsocketMessage = (audioBlob: any ) => {
    ws.send(audioBlob);
    return ({
        type: 'SEND_WEBSOCKET_MESSAGE',
        payload: audioBlob,
    })
};

export const createNewWebsocketConnection = (url: any ) => {
    ws = createWebSocket(url);
    return ({
        type: 'CREATE_NEW_WEBSOCKET'
    })
};

export const closeWebsocketConnection = () => {
    ws.send(TAG_END_OF_SENTENCE);
    return ({
        type: 'CLOSE_WEBSOCKET_CONNECTION'
    })
};

export const clearMessages = () => ({
    type: 'CLEAR_MESSAGES',
});

export const changeRecordingTitle = () => ({
    type: 'CHANGE_RECORDING_TITLE',
});

export const getRecordings = (userID: any ) => {
    return {
        type: 'GET_RECORDINGS',
        payload: recordingAPI.getRecordings(userID),
    }
}

export const getRecordingById = (id: any, userID: any ) => {
    return {
        type: 'GET_RECORDING_BY_ID',
        payload: recordingAPI.getRecordingById(id, userID),
    }
}

export const saveRecording = (newRecording: any ) => {
    return {
        type: 'SAVE_RECORDING',
        payload: recordingAPI.saveRecording(newRecording),
    }
}

export const updateRecording = (id: any, updatedRecording: any ) => {
    return {
        type: 'UPDATE_RECORDING',
        payload: recordingAPI.updateRecording(id, updatedRecording),
    }
}

export const deleteRecording = (id: any, userID: any ) => {
    return {
        type: 'DELETE_RECORDING',
        payload: recordingAPI.deleteRecording(id, userID),
    }
}

export const getIncrementalRecordingNumber = (userID: any ) => {
    return {
        type: 'GET_INCREMENTAL_RECORDING_NUMBER',
        payload: recordingAPI.getIncrementalRecordingNumber(userID),
    }
}

export const getRecordingsByAdmin = (searchParams: any ) => {
    return {
        type: 'GET_RECORDINGS_BY_ADMIN',
        payload: recordingAPI.getRecordingsByAdmin(searchParams),
    }
}

export const getSearchParams = () => {
    return {
        type: 'GET_SEARCH_PARAMS',
        payload: recordingAPI.getSearchParams(),
    }
}
