import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { Card, Elevation } from "@blueprintjs/core";

import './AsrBox.scss';

const mapStateToProps = (state) => ({
  finalResults: state.recorder.finalResults,
  tempResults: state.recorder.tempResults,
  dictateActivated: state.translation.dictateActivated,
  user: state.user
});

interface IState {
  user?: String,
  showSettings?: any,
  element?: any
}

interface IProp {
  user:any,
  serverMessage?:any,
  finalResults?:any,
  tempResults?:any
}


const mapDispatchToProps = (dispatch) => ({});

class AsrBox extends Component<IProp, IState> {
  
  updateScroll = () => {
    const element: any = document.getElementById("asr-box");
    element.scrollTop = element.scrollHeight;
  }

  componentDidUpdate(prevProps) {
    if (this.props.finalResults !== prevProps.finalResults || this.props.tempResults !== prevProps.tempResults) {
      this.updateScroll();
    }
  }

  renderSentences = (hypothese, key) => {
    if (hypothese.transcript === undefined) {
      return
    }

    const showAsrPreviewText = _.get(this.props, 'user.showAsrPreviewText', true);

    if (showAsrPreviewText === false && hypothese.temp) {
      return
    }

    if (this.props.user.dictateActivated) {
      if (hypothese.transcript.includes('\n')) {
        return (
          <>
            <br></br>
            <span key={key} style={{fontSize: `${this.props.user.fontSize}px`}} {...hypothese.temp && { className: 'temp-hypothese' }}>{hypothese.transcript}&nbsp;</span>
          </>
        );
      }
  
      return (
        <span key={key} style={{fontSize: `${this.props.user.fontSize}px`}} {...hypothese.temp && { className: 'temp-hypothese' }}>{hypothese.transcript}&nbsp;</span>
      )
    }
    
    return (
      <p key={key} style={{fontSize: `${this.props.user.fontSize}px`}} {...hypothese.temp && { className: 'temp-hypothese' }}>{hypothese.transcript}&nbsp;</p>
    )
  }
  
  render() {
    const {finalResults = [], tempResults = []} = this.props;
    const results = [...finalResults, ...tempResults];
    
    return (
      <Card id="asr-box" style={{ height: window._env.REACT_APP_TEXT_EDITOR_HEIGHT }} className="results" interactive={false} elevation={Elevation.FOUR}>
        {
          results.map((hypothese, key) => {
            return this.renderSentences(hypothese, key);
          })
        }
      </Card>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AsrBox);
